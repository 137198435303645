'use client';

import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { Star, ExternalLink } from 'lucide-react';
import Link from 'next/link';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"

export default function TestimonialsSection() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchReviews() {
      try {
        const { data, error } = await supabase
          .from('reviews')
          .select(`
            id,
            rating,
            comment,
            created_at,
            jobs (
              title,
              description
            ),
            client:client_id (
              id,
              name,
              avatar_url
            ),
            worker:worker_id (
              id,
              name,
              avatar_url
            )
          `)
          .order('created_at', { ascending: false })
          .limit(6);

        if (error) {
          console.log('Query error:', error);
          throw error;
        }

        console.log('Fetched reviews:', data);
        setReviews(data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchReviews();
  }, []);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        className={`w-4 h-4 ${
          index < rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'
        }`}
      />
    ));
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[...Array(6)].map((_, index) => (
          <Card key={index} className="w-full bg-white/10 backdrop-blur-lg border-white/10">
            <CardHeader>
              <Skeleton className="h-4 w-2/3 bg-white/5" />
              <Skeleton className="h-4 w-24 mt-2 bg-white/5" />
            </CardHeader>
            <CardContent>
              <Skeleton className="h-4 w-full mb-2 bg-white/5" />
              <Skeleton className="h-4 w-full mb-2 bg-white/5" />
              <Skeleton className="h-4 w-2/3 bg-white/5" />
            </CardContent>
            <CardFooter className="border-t border-white/10 pt-4">
              <div className="flex items-center w-full justify-between">
                <div className="flex items-center">
                  <Skeleton className="h-10 w-10 rounded-full bg-white/5" />
                  <div className="ml-3">
                    <Skeleton className="h-4 w-24 mb-2 bg-white/5" />
                    <Skeleton className="h-3 w-16 bg-white/5" />
                  </div>
                </div>
                <Skeleton className="h-9 w-28 bg-white/5" />
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <section className="py-16 md:py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Experiențe Reale
          </h2>
          <p className="text-gray-600 text-lg">
            Vezi ce spun clienții despre meseriașii noștri
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
          {reviews.map((review) => (
            <Card 
              key={review.id} 
              className="flex flex-col w-full shadow-lg hover:shadow-xl transition-shadow"
            >
              <CardHeader>
                <CardTitle className="flex items-center justify-between">
                  <span className="text-lg font-semibold text-gray-900 line-clamp-1">
                    {review.jobs?.title}
                  </span>
                  <Badge variant="secondary" className="bg-gray-100 text-gray-700 shrink-0 ml-2">
                    {review.rating.toFixed(1)}
                  </Badge>
                </CardTitle>
                <div className="flex mt-2">{renderStars(review.rating)}</div>
              </CardHeader>
              
              <CardContent className="flex-grow">
                <blockquote className="text-gray-700 italic text-sm leading-relaxed min-h-[80px]">
                  "{review.comment}"
                </blockquote>
              </CardContent>
              
              <CardFooter className="border-t pt-4 mt-auto">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <Avatar className="h-10 w-10 border border-gray-200">
                      <AvatarImage 
                        src={review.client?.avatar_url} 
                        alt={review.client?.name || 'Avatar client'} 
                      />
                      <AvatarFallback className="bg-gray-100 text-gray-700">
                        {review.client?.name?.charAt(0) || '?'}
                      </AvatarFallback>
                    </Avatar>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {review.client?.name || 'Client Anonim'}
                      </p>
                      <p className="text-xs text-gray-500">
                        {new Date(review.created_at).toLocaleDateString('ro-RO')}
                      </p>
                    </div>
                  </div>
                  
                  {review.worker?.id && (
                    <Link 
                      href={`/workers/${review.worker.id}`}
                      className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 
                               bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors shrink-0 ml-4"
                    >
                      Vezi profilul
                      <ExternalLink className="ml-2 h-4 w-4" />
                    </Link>
                  )}
                </div>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}