import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaUser, FaEnvelope, FaLock, FaEye, FaEyeSlash, FaPhone, FaMailBulk } from 'react-icons/fa';
import { ArrowRight } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import Link from 'next/link';


const RegisterClient = ({onRegisterSuccess, jobData }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const { name, email, phone, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError('Parolele nu se potrivesc.');
      return;
    }

    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            name,
            phone,
            role: 'client',
            pendingJobId: jobData?.id
          },
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) throw error;

      if (data.user) {
        const { error: profileError } = await supabase
          .from('profiles')
          .upsert([
            {
              id: data.user.id,
              name,
              email,
              phone,
              role: 'client'
            }
          ], { onConflict: 'id' });

        if (profileError) throw profileError;

        onRegisterSuccess({ ...data.user, role: 'client' });
        setIsVerificationSent(true);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setError(error.message);
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className="w-full bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="w-full bg-gray-200 h-1">
        <div 
          className="bg-gray-900 h-1 transition-all duration-500"
          style={{ width: "100%" }}
        />
      </div>

      <div className="flex flex-col px-6 py-6">
        {isVerificationSent ? (
          <div className="text-center py-8">
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <FaMailBulk className="w-8 h-8 text-gray-900" />
            </div>

            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Verifică-ți emailul
            </h3>
            <p className="text-gray-600 mb-6">
              Am trimis un link de confirmare la adresa <span className="font-semibold">{formData.email}</span>.<br/>
              Te rugăm să verifici inbox-ul și să confirmi contul.
            </p>

            <div className="space-y-4">
              <Link 
                href="https://mail.google.com" 
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full px-6 py-3 bg-gray-900 hover:bg-gray-800 
                  text-white rounded-lg transition-colors"
              >
                Deschide Gmail
                <ArrowRight className="w-4 h-4 ml-2" />
              </Link>

              <Link 
                href="/login" 
                className="flex items-center justify-center w-full px-6 py-3 border border-gray-300 
                  text-gray-900 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Mergi la Login
              </Link>
            </div>

            <div className="mt-8 p-4 bg-gray-50 rounded-lg border border-gray-200">
              <p className="text-sm text-gray-600">
                Nu ai primit emailul? Verifică și folderul de Spam sau contactează-ne la 
                <Link href="mailto:contact@meseriaslocal.ro" className="font-medium text-gray-900 hover:underline"> contact@meseriaslocal.ro</Link>
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-xl font-semibold text-gray-900 mb-2 text-center">
              Creează cont pentru a continua
            </h3>
            <p className="text-sm text-gray-600 mb-6 text-center">
              Completează datele tale pentru a finaliza cererea
            </p>

            <form onSubmit={handleSubmit} className="w-full space-y-4">
              <InputField
                icon={<FaUser className="text-gray-400" />}
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Nume complet"
                required
                className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 
                  text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-gray-900 focus:border-transparent
                  transition-all duration-200"
              />
              <InputField
                icon={<FaEnvelope className="text-gray-400" />}
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Adresa de email"
                required
                className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 
                  text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-gray-900 focus:border-transparent
                  transition-all duration-200"
              />
              <InputField
                icon={<FaPhone className="text-gray-400" />}
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Număr de telefon"
                required
                className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 
                  text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-gray-900 focus:border-transparent
                  transition-all duration-200"
              />
              <PasswordField
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Parolă"
                showPassword={showPassword}
                toggleVisibility={() => togglePasswordVisibility('password')}
                className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 
                  text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-gray-900 focus:border-transparent
                  transition-all duration-200"
              />
              <PasswordField
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirmă parola"
                showPassword={showConfirmPassword}
                toggleVisibility={() => togglePasswordVisibility('confirmPassword')}
                className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 
                  text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-gray-900 focus:border-transparent
                  transition-all duration-200"
              />

              {error && (
                <div className="bg-red-50 border border-red-200 px-4 py-3 rounded-lg">
                  <p className="text-sm text-red-600">{error}</p>
                </div>
              )}

              <div className="pt-4 border-t border-gray-200">
                <motion.button
                  type="submit"
                  className="w-full px-4 py-3 text-sm font-medium bg-gray-900 hover:bg-gray-800 
                    text-white rounded-lg transition-all duration-200 shadow-sm 
                    flex items-center justify-center"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Finalizare Înregistrare
                </motion.button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

const InputField = ({ icon, className, ...props }) => (
  <div className="relative group">
    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 
      group-hover:text-gray-400 transition-colors duration-200">
      {icon}
    </span>
    <input
      {...props}
      className={`${className} hover:border-gray-600 focus:border-gray-500`}
      style={{ paddingLeft: "2.5rem" }}
    />
  </div>
);

const PasswordField = ({ showPassword, toggleVisibility, className, ...props }) => (
  <div className="relative group">
    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 
      group-hover:text-gray-400 transition-colors duration-200">
      <FaLock />
    </span>
    <input
      {...props}
      type={showPassword ? "text" : "password"}
      className={`${className} hover:border-gray-600 focus:border-gray-500`}
      style={{ paddingLeft: "2.5rem" }}
    />
    <button
      type="button"
      onClick={toggleVisibility}
      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 
        hover:text-gray-400 focus:outline-none transition-colors duration-200"
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </button>
  </div>
);

export default RegisterClient;
