'use client'

import Link from 'next/link'
import { FaArrowRight, FaHardHat, FaTools, FaPaintRoller } from 'react-icons/fa'
import ServiceRequestForm from '@/components/forms/ServiceRequestForm'

import ServiceStats from '../services/ServiceStats'
import { tradesMapping } from '@/data/serviceCategories'

const DynamicHero = ({ 
  breadcrumbs,
  title,
  subtitle,
  description,
  showForm = true,
  showCTA = true,
  cityFromUrl,
  className = "",
  currentCategory,
}) => {
  const relevantTrades = Object.entries(tradesMapping)
    .filter(([trade, [category]]) => category === currentCategory)
    .map(([trade]) => trade);

  return (
    <section className={`relative overflow-hidden text-white min-h-[70vh] flex items-center justify-center ${className} py-12`}>
      {/* Background Layers */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-black" />
        
        {/* Pattern overlay pentru textură (opțional) */}
        <div 
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.15) 1px, transparent 0)`,
            backgroundSize: '24px 24px'
          }}
        />
        
        {/* Overlay gradient pentru adâncime */}
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900/50 to-transparent" />
      </div>

      <div className="relative z-10 container mx-auto px-6">
        {breadcrumbs && (
          <nav className="mb-6" aria-label="Breadcrumb">
            <ol className="flex flex-wrap items-center space-x-2 text-gray-400 text-sm">
              {breadcrumbs.map((crumb, index) => (
                <li key={index} className="flex items-center">
                  {index < breadcrumbs.length - 1 ?  (
                    <>
                      <Link href={crumb.href} className="hover:text-gray-200 transition duration-300">
                        {crumb.text}
                      </Link>
                      <span className="mx-2 text-gray-500">›</span>
                    </>
                  ) : (
                    <span className="text-gray-200">{crumb.text}</span>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        )}

        <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center max-w-7xl mx-auto">
          <div className="space-y-6">
            <div className="space-y-2">
              <h1 className="text-2xl sm:text-2xl lg:text-2xl font-bold animate-fade-in">
                {title} 
              </h1>

              {subtitle && (
                <h2 className="text-1xl sm:text-2xl lg:text-2xl text-gray-300 font-light leading-tight animate-fade-in">
                  {subtitle}
                  
                </h2>
              )}

              {description && (
                <p className="text-gray-400 text-lg mt-4 animate-fade-in">
                  {description}
                </p>
              )}
              
              <div className="hidden md:block">
                <ServiceStats />
              </div>
            </div>

            {showCTA && (
              <div className="space-y-4 pt-2 animate-fade-in hidden md:block">
                <div className="flex flex-wrap gap-4">
                  {[
                    { icon: FaHardHat, text: "Profesioniști Calificați" },
                    { icon: FaTools, text: "Echipamente Moderne" },
                    { icon: FaPaintRoller, text: "Finisaje de Calitate" }
                  ].map((item, index) => (
                    <div key={index} className="flex items-center text-gray-200">
                      <item.icon className="mr-2" />
                      <span>{item.text}</span>
                    </div>
                  ))}
                </div>

                <Link 
                  href="/register/worker"
                  className="inline-flex items-center bg-gray-200 text-gray-900 px-6 py-3 rounded-md font-medium hover:bg-gray-300 transition duration-300"
                >
                  Devino Meserias Pro
                  <FaArrowRight className="ml-2" />
                </Link>
              </div>
            )}
          </div>

          {showForm && (
            <div className="lg:mt-0 animate-fade-in">
              <div className="p-0">
                <ServiceRequestForm 
                  cityFromUrl={cityFromUrl} 
                  category={currentCategory}
                  relevantTrades={relevantTrades}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default DynamicHero