"use client";

import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { motion, AnimatePresence } from 'framer-motion';

import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useAuth } from '@/context/AuthContext';
import { supabase } from '@/lib/supabase';
import { useRouter } from 'next/navigation';
import { serviceCategories, tradesMapping } from '@/data/serviceCategories';
import RegisterClient from '@/components/hero/steps/registerClient';

import CityAutocomplete from './CityAutocomplete';
import { cities } from '@/data/cities';

const ServiceRequestForm = ({ cityFromUrl, category, relevantTrades }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [currentStep, setCurrentStep] = useState(0);
  const [showRegister, setShowRegister] = useState(false);
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});

  // Inițializăm formData cu valori default
  const [formData, setFormData] = useState({
    category: category,
    subcategory: '',
    city: cityFromUrl || '',
    description: '',
    startDate: '',
    budget: ''
  });

  // Definim isOnCityPage aici
  const pathParts = pathname.split('/').filter(Boolean);
  const isOnCityPage = pathParts.length === 4 && pathParts[0] === 'servicii';

  // Definim pașii formularului
  const steps = [
    {
      id: 'service',
      question: "Ce tip de serviciu aveți nevoie?",
      description: "Selectați categoria și tipul specific de serviciu",
      type: "service-select",
      required: true
    },
    {
      id: 'description',
      question: "Descrieți proiectul în detaliu",
      description: "Includeți cât mai multe detalii despre ce trebuie făcut",
      type: "textarea",
      placeholder: "Descrieți detaliat lucrarea...",
      required: true
    },
    {
      id: 'startDate',
      question: "Când doriți să înceapă lucrarea?",
      description: "Selectați perioada aproximativă de început",
      type: "select",
      options: [
        { value: 'urgent', label: 'Urgent - Cât mai curând posibil' },
        { value: '2days', label: 'În următoarele 2 zile' },
        { value: '1week', label: 'În următoarea săptămână' },
        { value: '2weeks', label: 'În următoarele 2 săptămâni' },
        { value: 'flexible', label: 'Sunt flexibil cu perioada' }
      ],
      required: true
    },
    {
      id: 'budget',
      question: "Care este bugetul estimat?",
      description: "Selectați un interval de buget aproximativ",
      type: "radio",
      options: [
        { value: 'under1000', label: 'Sub 1.000 RON' },
        { value: '1000-3000', label: '1.000 - 3.000 RON' },
        { value: '3000-5000', label: '3.000 - 5.000 RON' },
        { value: '5000-10000', label: '5.000 - 10.000 RON' },
        { value: 'over10000', label: 'Peste 10.000 RON' }
      ],
      required: true
    }
  ];

  // Funcție pentru a gsi orașul corect din lista noastră
  const getNormalizedCity = (urlCity) => {
    if (!urlCity) return '';
    
    // Convertim slug-ul în format normal
    return cities.find(city => 
      city.toLowerCase().replace(/\s+/g, '-') === urlCity.toLowerCase()
    ) || '';
  };

  // Pre-completează serviciul bazat pe URL
  useEffect(() => {
    if (pathParts.includes('servicii')) {
      const categoryId = pathParts[1];
      const subcategoryId = pathParts[2];
      
      setFormData(prev => ({
        ...prev,
        category: categoryId || '',
        subcategory: subcategoryId || '',
        city: cityFromUrl || prev.city || '' // Păstrăm valoarea existentă dacă nu avem cityFromUrl
      }));
    }
  }, [pathname, cityFromUrl]);

  useEffect(() => {
    const normalizedCity = getNormalizedCity(cityFromUrl);
    if (normalizedCity) {
      handleAnswer(normalizedCity);
    }
  }, [cityFromUrl]);

  const handleAnswer = (answer, field = steps[currentStep].id) => {
    setFormData(prev => ({
      ...prev,
      [field]: answer
    }));
  };

  const validateStep = () => {
    const newErrors = {};

    switch (currentStep) {
      case 0: // Serviciu și oraș
        if (!formData.category) {
          newErrors.category = 'Te rugăm să selectezi o categorie';
        }
        if (!formData.subcategory) {
          newErrors.subcategory = 'Te rugăm să selectezi un tip de serviciu';
        }
        if (!formData.city) {
          newErrors.city = 'Te rugăm să selectezi orașul';
        }
        break;

      case 1: // Descriere
        if (!formData.description) {
          newErrors.description = 'Te rugăm să adaugi o descriere';
        } else if (formData.description.length < 20) {
          newErrors.description = 'Descrierea trebuie să conțină cel puțin 20 de caractere';
        }
        break;

      case 2: // Data start
        if (!formData.startDate) {
          newErrors.startDate = 'Te rugăm să selectezi perioada de început';
        }
        break;

      case 3: // Buget
        if (!formData.budget) {
          newErrors.budget = 'Te rugăm să selectezi bugetul estimat';
        }
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const goToNextStep = () => {
    if (!validateStep()) {
      return;
    }

    if (currentStep === steps.length - 1) {
      handleSubmit();
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setError(''); // Ștergem orice eroare când mergem înapoi
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser()
      
      if (!user) {
        // Salvăm datele în localStorage și arătăm formularul de înregistrare
        localStorage.setItem('pendingJobData', JSON.stringify(formData));
        setShowRegister(true);
        return;
      }

      // Convertim bugetul în număr
      const getBudgetValue = (budgetRange) => {
        switch(budgetRange) {
          case 'under1000': return 1000;
          case '1000-3000': return 3000;
          case '3000-5000': return 5000;
          case '5000-10000': return 10000;
          case 'over10000': return 15000;
          default: return 0;
        }
      };

      // Construim titlul din categoria și subcategoria selectată
      const selectedCategory = serviceCategories.find(cat => cat.id === formData.category);
      const selectedSubcategory = selectedCategory?.subcategories.find(sub => sub.id === formData.subcategory);
      
      // Găsim trade-ul potrivit din tradesMapping
      const trade = Object.entries(tradesMapping)
        .find(([tradeName, [category, subcategories]]) => 
          subcategories.includes(formData.subcategory)
        )?.[0];

      const jobData = {
        title: `${selectedCategory?.name} - ${selectedSubcategory?.name}`,
        description: formData.description,
        budget: getBudgetValue(formData.budget),
        address: formData.city,
        client_id: user.id,
        tradeType: trade || 'general', // Folosim numele meseriei găsite
        jobType: formData.subcategory,
        urgency: formData.startDate === 'urgent' ? 'high' : 
                 formData.startDate === '2days' ? 'medium' : 'low',
        status: 'open',
        created_at: new Date().toISOString()
      }

      const { data, error } = await supabase
        .from('jobs')
        .insert([jobData])
        .select()

      if (error) throw error

      // Redirect sau reset form după succes
      router.push('/dashboard/client');
      
    } catch (error) {
      console.error('Error:', error)
      setError('A apărut o eroare la salvarea lucrării')
    }
  }

  const handleRegisterSuccess = async (userData) => {
    try {
      if (!userData) {
        throw new Error('Nu s-au primit datele utilizatorului');
      }

      const savedData = localStorage.getItem('pendingJobData');
      if (savedData) {
        try {
          const parsedData = JSON.parse(savedData);
          
          // Găsim trade-ul potrivit din tradesMapping
          const trade = Object.entries(tradesMapping)
            .find(([tradeName, [category, subcategories]]) => 
              subcategories.includes(parsedData.subcategory)
            )?.[0];

          // Construim datele pentru job
          const dataToInsert = {
            title: `${serviceCategories.find(cat => cat.id === parsedData.category)?.name} - ${
              serviceCategories
                .find(cat => cat.id === parsedData.category)
                ?.subcategories.find(sub => sub.id === parsedData.subcategory)?.name
            }`,
            description: parsedData.description,
            budget: parsedData.budget === 'under1000' ? 1000 :
                    parsedData.budget === '1000-3000' ? 3000 :
                    parsedData.budget === '3000-5000' ? 5000 :
                    parsedData.budget === '5000-10000' ? 10000 : 15000,
            status: 'open',
            created_at: new Date().toISOString(),
            address: parsedData.city,
            tradeType: trade || 'general',
            jobType: parsedData.subcategory,
            urgency: parsedData.startDate === 'urgent' ? 'high' : 
                     parsedData.startDate === '2days' ? 'medium' : 'low',
            client_id: userData.id
          };

          const { error: supabaseError } = await supabase
            .from('jobs')
            .insert([dataToInsert])
            .select();

          if (supabaseError) throw supabaseError;
          
          // Ștergem datele din localStorage doar după ce jobul a fost salvat cu succes
          localStorage.removeItem('pendingJobData');
          
          // Eliminăm doar redirectul
          // router.push('/dashboard/client'); <- Această linie a fost ștearsă
        } catch (err) {
          console.error('Error creating job:', err);
          throw new Error('A apărut o eroare la salvarea jobului');
        }
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message || 'A apărut o eroare la înregistrare. Te rugăm să încerci din nou.');
    }
  };

  const renderStep = () => {
    console.log('Current Step:', currentStep);
    console.log('Form Data:', formData);

    switch (currentStep) {
      case 0:
        return (
          <div className="space-y-4">
            <div>
              <select
                value={formData.category || ''}
                onChange={(e) => handleAnswer(e.target.value, 'category')}
                className={`w-full px-4 py-3 bg-white border rounded-lg
                  ${errors.category ? 'border-red-500' : 'border-gray-300'}
                  focus:ring-2 focus:ring-gray-900 focus:border-transparent transition-colors`}
              >
                <option value="">Selectează categoria</option>
                {serviceCategories.map(category => (
                  <option key={category.id} value={category.id} className="text-gray-900">
                    {category.name}
                  </option>
                ))}
              </select>
              {errors.category && (
                <p className="mt-1 text-sm text-red-600">{errors.category}</p>
              )}
            </div>

            <div>
              <select
                value={formData.subcategory || ''}
                onChange={(e) => handleAnswer(e.target.value, 'subcategory')}
                className={`w-full px-4 py-3 bg-white border rounded-lg
                  ${errors.subcategory ? 'border-red-500' : 'border-gray-300'}
                  focus:ring-2 focus:ring-gray-900 focus:border-transparent transition-colors`}
              >
                <option value="">Selectează tipul de serviciu</option>
                {formData.category && serviceCategories
                  .find(cat => cat.id === formData.category)
                  ?.subcategories.map(sub => (
                    <option key={sub.id} value={sub.id} className="text-gray-900">
                      {sub.name}
                    </option>
                  ))}
              </select>
              {errors.subcategory && (
                <p className="mt-1 text-sm text-red-600">{errors.subcategory}</p>
              )}
            </div>

            <div>
              <CityAutocomplete
                initialValue={formData.city || ''}
                onSelect={(city) => handleAnswer(city, 'city')}
                error={errors.city}
              />
              {errors.city && (
                <p className="mt-1 text-sm text-red-600">{errors.city}</p>
              )}
            </div>
          </div>
        );

      case 1:
        return (
          <div className="space-y-4">
            <textarea
              value={formData.description || ''}
              onChange={(e) => handleAnswer(e.target.value, 'description')}
              placeholder="Descrieți detaliat lucrarea..."
              className={`w-full px-4 py-3 bg-white border rounded-lg min-h-[150px] resize-none
                ${errors.description ? 'border-red-500' : 'border-gray-300'}
                focus:ring-2 focus:ring-gray-900 focus:border-transparent transition-colors`}
            />
            {errors.description && (
              <p className="text-sm text-red-600">{errors.description}</p>
            )}
            <p className="text-sm text-gray-500">
              {formData.description?.length || 0}/20 caractere minim
            </p>
          </div>
        );

      case 2:
        return (
          <div className="space-y-4">
            <select
              value={formData.startDate || ''}
              onChange={(e) => handleAnswer(e.target.value, 'startDate')}
              className={`w-full px-4 py-3 bg-white border rounded-lg
                ${errors.startDate ? 'border-red-500' : 'border-gray-300'}
                focus:ring-2 focus:ring-gray-900 focus:border-transparent transition-colors`}
            >
              <option value="">Selectează perioada</option>
              <option value="urgent" className="text-gray-900">Urgent - Cât mai curând posibil</option>
              <option value="2days" className="text-gray-900">În următoarele 2 zile</option>
              <option value="1week" className="text-gray-900">În următoarea săptămână</option>
              <option value="2weeks" className="text-gray-900">În următoarele 2 săptămâni</option>
              <option value="flexible" className="text-gray-900">Sunt flexibil cu perioada</option>
            </select>
            {errors.startDate && (
              <p className="text-sm text-red-600">{errors.startDate}</p>
            )}
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {steps[3].options.map((option) => (
                <button
                  key={option.value}
                  type="button"
                  onClick={() => handleAnswer(option.value, 'budget')}
                  className={`p-4 rounded-lg border transition-colors text-left
                    ${formData.budget === option.value 
                      ? 'bg-yellow-400/20 border-yellow-400' 
                      : errors.budget
                        ? 'bg-white/10 border-red-500'
                        : 'bg-white/10 border-white/20'
                    }`}
                >
                  <span className="font-medium">{option.label}</span>
                </button>
              ))}
            </div>
            {errors.budget && (
              <p className="text-sm text-red-600">{errors.budget}</p>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  if (showRegister) {
    return (
      <div className="w-full h-full">
        <RegisterClient 
          onRegisterSuccess={handleRegisterSuccess} 
          formData={formData} 
        />
      </div>
    );
  }

  return (
    <div className="py-6 sm:py-6 ">
      <div className="container px-0 mx-auto">
        <div className="max-w-3xl mx-auto">
          {/* Header section */}
          <div className="text-center mb-6">
            <div className="flex items-center justify-center gap-2 mb-2">
              <div className="w-2 h-2 rounded-full bg-gray-200"></div>
              <span className="text-sm font-medium text-gray-200">
                Postează un Proiect
              </span>
            </div>
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-200 mb-2">
              Descrie-ți Proiectul
            </h2>
          </div>

          {/* Progress bar */}
          <div className="w-full bg-black rounded-full h-1 mb-4">
            <div 
              className="bg-gray-200 h-1 rounded-full transition-all duration-500 ease-in-out" 
              style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
            ></div>
          </div>

          {/* Main form content */}
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-xl shadow-lg p-3 text-black"
          >
            <h3 className="text-xl font-bold text-black mb-2">
              {steps[currentStep].question}
            </h3>
            <p className="text-sm text-black mb-4">
              {steps[currentStep].description}
            </p>

            <div className="space-y-3">
              {renderStep()}
            </div>

            {/* Error message */}
            {error && (
              <div className="mt-4 p-3 rounded-lg bg-red-50 border border-red-200 text-red-600 text-sm">
                {error}
              </div>
            )}
          </motion.div>

          {/* Navigation buttons */}
          <div className="flex justify-between mt-6">
            <button
              onClick={goToPreviousStep}
              className={`inline-flex items-center justify-center h-10 px-5 font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 transition-colors rounded-md ${
                currentStep === 0 ? 'invisible' : ''
              }`}
            >
              <ChevronLeft className="w-4 h-4 mr-2" />
              Înapoi
            </button>
            <button
              onClick={goToNextStep}
              className="inline-flex items-center justify-center h-10 px-5 font-medium text-black bg-gray-200 hover:bg-gray-300 transition-colors rounded-md"
            >
              {currentStep === steps.length - 1 ? 'Finalizare' : 'Următorul'}
              <ChevronRight className="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestForm;
