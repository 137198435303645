import { useState, useRef, useEffect } from 'react';
import { cities } from '@/data/cities';
import { FaSearch } from 'react-icons/fa';

const CityAutocomplete = ({ onSelect, initialValue = '' }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const getSuggestions = (input) => {
    const inputValue = input.toLowerCase();
    return cities.filter(city =>
      city.toLowerCase().includes(inputValue)
    ).slice(0, 10);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    
    if (value.length > 0) {
      setSuggestions(getSuggestions(value));
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSelect = (city) => {
    setInputValue(city);
    if (onSelect) {
      onSelect(city);
    }
    setShowSuggestions(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={wrapperRef} className="relative">
      <div className="relative">
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => inputValue && setShowSuggestions(true)}
          placeholder="Introdu numele orașului"
          className="w-full px-4 py-3 pl-10 bg-white border-2 border-gray-300 rounded-md
            text-gray-900 placeholder-gray-500 
            focus:outline-none focus:ring-2 focus:ring-gray-900 focus:border-transparent
            transition-all duration-300"
        />
      </div>
      
      {showSuggestions && suggestions.length > 0 && (
        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-200 
          rounded-md shadow-lg max-h-60 overflow-auto">
          {suggestions.map((city) => (
            <li
              key={city}
              onClick={() => handleSelect(city)}
              className="px-4 py-2 hover:bg-gray-50 cursor-pointer text-gray-900 
                transition-colors border-b border-gray-100 last:border-b-0"
            >
              {city}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CityAutocomplete;
