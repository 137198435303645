'use client';

import { useState, useEffect } from 'react';
import { client } from '@/lib/prismic';
import { PrismicRichText } from '@prismicio/react';
import Link from 'next/link';
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Clock, ArrowRight } from 'lucide-react';

const simpleComponents = {
  paragraph: ({ children }) => <p>{children}</p>,
  strong: ({ children }) => <strong>{children}</strong>,
  em: ({ children }) => <em>{children}</em>,
  // Dezactivează link-urile în preview
  hyperlink: ({ children }) => <span>{children}</span>
};

export default function RecentArticles() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const response = await client.getAllByType('blog_post', {
          orderings: {
            field: 'document.first_publication_date',
            direction: 'desc',
          },
          limit: 3 // Doar ultimele 3 articole
        });
        
        setArticles(response);
      } catch (error) {
        console.error('Error fetching recent posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentPosts();
  }, []);

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {[1, 2, 3].map((i) => (
          <Card key={i} className="animate-pulse">
            <div className="h-48 bg-gray-200 rounded-t-lg" />
            <CardContent className="p-6">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4" />
              <div className="h-8 bg-gray-200 rounded mb-4" />
              <div className="h-4 bg-gray-200 rounded w-1/2" />
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-3xl font-bold text-gray-900">Articole Recente</h2>
            <p className="text-gray-600 mt-2">Sfaturi și noutăți pentru casa ta</p>
          </div>
          <Button variant="outline" asChild>
            <Link href="/blog" className="flex items-center gap-2">
              Vezi toate articolele
              <ArrowRight className="w-4 h-4" />
            </Link>
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {articles.map((article) => (
            <Link 
              href={`/blog/${article.uid}`} 
              key={article.id}
              className="group"
            >
              <Card className="h-full transition-shadow hover:shadow-lg">
                {article.data.imagine_principala?.url && (
                  <div className="relative h-48 overflow-hidden rounded-t-lg">
                    <img
                      src={article.data.imagine_principala.url}
                      alt={article.data.imagine_principala.alt || ''}
                      className="w-full h-full object-cover transition-transform group-hover:scale-105"
                    />
                  </div>
                )}
                <CardContent className="p-6">
                  {article.data.categorie && (
                    <span className="inline-block px-3 py-1 text-sm font-medium text-gray-900 bg-gray-100 rounded-full mb-3">
                      {article.data.categorie}
                    </span>
                  )}
                  <h3 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-gray-700 transition-colors">
                    {article.data.titlu}
                  </h3>
                  <div className="text-gray-600 mb-4 line-clamp-2">
                    <PrismicRichText 
                      field={article.data.pentru_descriere_scurta} 
                      components={simpleComponents}
                    />
                  </div>
                  {article.data.timp_de_citire && (
                    <div className="flex items-center text-sm text-gray-500">
                      <Clock className="w-4 h-4 mr-1" />
                      {article.data.timp_de_citire} min citire
                    </div>
                  )}
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
